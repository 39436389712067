/* eslint-disable import/order */
import { type Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';

import { PermissionsGuard } from './../core/guards/permissions.guard';
import { ProductsListComponent } from './products-list/products-list.component';
import { productsListGuard } from './products-list.guard';
import { productsListResolver } from './products-list.resolver';
import { ProductsPageComponent } from './products-page.component';

// States has to be imported last due to a bug in @ngxs/store
// @see: https://github.com/ngxs/store/issues/1631
// This comment is here to prevent import sorter from reordering the imports
import { ProductsState } from './state/products.state';
import { ProductEditComponent } from '@clover/products/product-edit/product-edit.component';
import { ProductEditRouterResolver } from '@clover/products/product-edit/product-edit-route-resolver';
import { ProductViewComponent } from '@clover/products/product-view/product-view.component';

export const PRODUCTS_BASE_URL = 'products';

export const productsPageRoutes: Routes = [
  {
    path: '',
    providers: [provideStates([ProductsState])],
    component: ProductsPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        resolve: {
          productsLoaded: productsListResolver,
        },
        data: {
          permissions: ['Product_NavMenuViewV2'],
        },
        canActivate: [PermissionsGuard, productsListGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        component: ProductsListComponent,
      },
    ],
  },

  // Legacy routes
  {
    path: 'create',
    component: ProductEditComponent,
    data: {
      permissions: ['Product_Create'],
    },
    canActivate: [PermissionsGuard],
  },
  {
    path: ':id/edit',
    component: ProductEditComponent,
    data: {
      permissions: ['Product_Edit'],
    },
    canActivate: [PermissionsGuard],
    resolve: {
      user: ProductEditRouterResolver,
    },
  },
  {
    path: ':id',
    component: ProductViewComponent,
    data: {
      permissions: ['Product_View'],
    },
    canActivate: [PermissionsGuard],
    resolve: {
      user: ProductEditRouterResolver,
    },
  },
];
