import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { RouterLink } from '@angular/router';

import { UserPermissions } from '@clover/core/services/permission.service';
import { UserService } from '@clover/core/services/user.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

@Component({
  selector: 'cc-products-header',
  standalone: true,
  imports: [ButtonComponent, RouterLink],
  templateUrl: './products-header.component.html',
  styleUrl: './products-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsHeaderComponent {
  import = output<void>();
  private readonly userService = inject(UserService);
  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
}
